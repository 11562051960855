"use client";

import { useCallback } from "react";

import {
  invalidateUseGetNotificationsQuery,
  updateReadNotification,
} from "@saas/notification/data";
import { usePushNotificationOpenedListener } from "@saas/notification/utils";

export const PushNotificationListener = () => {
  const updateReadNotificationFn = useCallback(
    (melakaNotificationId: number) => {
      updateReadNotification({
        id: melakaNotificationId,
        isRead: true,
      })
        .then(() => {
          invalidateUseGetNotificationsQuery();
        })
        .catch(() => null);
    },
    []
  );

  /**
   * Listener to handle push notification opened
   * At the time of this development at 19 June, 2023 the onesignal listener is not supported on Safari
   * Refer to the docs: https://documentation.onesignal.com/docs/web-push-sdk#addlistenerfornotificationopened-event
   *
   * For other browser, the onesignal listener behavior seems to be incosistent as well.
   */
  usePushNotificationOpenedListener(updateReadNotificationFn);

  return null;
};

export default PushNotificationListener;
