import { useEffect } from "react";

import { initOneSignal, registerUserDevice } from "@saas/core";

type UseOneSignalParams = {
  enabled?: boolean;
  userId?: string;
  onOneSignalUserIdReceived: (oneSignalUserId: string) => void;
};

export const useOneSignal = ({
  enabled,
  userId,
  onOneSignalUserIdReceived,
}: UseOneSignalParams) => {
  useEffect(() => {
    if (enabled && userId) {
      initOneSignal().then(async () => {
        await registerUserDevice(userId, onOneSignalUserIdReceived);
      });
    }
  }, [enabled, onOneSignalUserIdReceived, userId]);
};

export default useOneSignal;
