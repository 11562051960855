"use client";

import { FetchError, queryClient } from "@saas/core";
import { QueryOptionsInterface } from "@saas/shared/utils";

import getSubaccounts, {
  GetSubaccountsInput,
  GetSubaccountsOutput,
} from "../../subaccount/get-subaccounts/get-subaccounts";
import subaccountQueryKeys from "../../subaccount/subaccount-query-keys/subaccount.query-keys";

import { Updater, useQuery } from "@tanstack/react-query";

export type UseSubaccountsQueryParams = GetSubaccountsInput;

export type UseSubaccountsQueryData = GetSubaccountsOutput;

export const useSubaccountsQuery = <DerivedQueryData = UseSubaccountsQueryData>(
  params: UseSubaccountsQueryParams,
  options?: QueryOptionsInterface<UseSubaccountsQueryData, DerivedQueryData>
) => {
  return useQuery<UseSubaccountsQueryData, FetchError, DerivedQueryData>(
    subaccountQueryKeys.all(),
    async () => {
      const data = await getSubaccounts(params);

      return data;
    },
    {
      ...options,
      select: options?.select,
      enabled: options?.enabled,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const setUseSubaccountsQuery = (
  params: UseSubaccountsQueryParams,
  updater: Updater<UseSubaccountsQueryData | undefined, UseSubaccountsQueryData>
): void => {
  queryClient.setQueryData<UseSubaccountsQueryData>(
    subaccountQueryKeys.all(),
    updater
  );
};

export const getUseSubaccountsQuery = (
  params: UseSubaccountsQueryParams
): UseSubaccountsQueryData | undefined => {
  return queryClient.getQueryData<UseSubaccountsQueryData>([
    subaccountQueryKeys.all(),
  ]);
};

export const prefetchUseSubaccountsQuery = async (
  params: UseSubaccountsQueryParams,
  cookie: string
): Promise<UseSubaccountsQueryData | null> => {
  let data: UseSubaccountsQueryData | null;

  try {
    data = await getSubaccounts(params, {
      headers: { cookie },
    });
  } catch {
    data = null;
  }

  await queryClient.prefetchQuery(subaccountQueryKeys.all(), () => data);

  return data;
};

export const invalidateUseSubaccountsQuery = (
  params?: UseSubaccountsQueryParams
) => {
  return queryClient.invalidateQueries(subaccountQueryKeys.all());
};

export default useSubaccountsQuery;
