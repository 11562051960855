"use client";

import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { classNames } from "@saas/shared/utils";

import { Typography } from "..";

import { Popover, Transition } from "@headlessui/react";
import { BasePlacement } from "@popperjs/core";
import { usePopper } from "react-popper";

export interface TooltipProps {
  children: ReactNode;
  content: string;
  testid?: string;
  enableHover?: boolean;
  customContent?: ReactNode;
  placement?: BasePlacement;
  className?: string;
}

export const Tooltip = ({
  children,
  className,
  content,
  testid = "",
  enableHover = false,
  customContent = undefined,
  placement = "top",
}: TooltipProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [openState, setOpenState] = useState(false);
  const [popperContent, setPopperContent] = useState<HTMLDivElement | null>(
    null
  );
  const [popperArrow, setPopperArrow] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(buttonRef.current, popperContent, {
    placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
      {
        name: "preventOverflow",
        options: {
          padding: 16,
        },
      },
      {
        name: "arrow",
        options: {
          element: popperArrow,
          padding: 8,
        },
      },
      {
        name: "flip",
        options: {
          allowedAutoPlacements: [],
          fallbackPlacements: [],
        },
      },
    ],
  });

  const toggleTooltip = () => {
    setOpenState((openState) => !openState);
    buttonRef?.current?.click();
  };

  const onHover = useCallback(
    (open: boolean, action: string) => {
      if (enableHover)
        if (
          (!open && !openState && action === "onMouseEnter") ||
          (open && openState && action === "onMouseLeave")
        ) {
          toggleTooltip();
        }
    },
    [enableHover, openState]
  );

  const handleClick = (open: boolean) => {
    setOpenState(!open);
  };

  const handleCloseTooltip = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (buttonRef.current && !buttonRef.current.contains(target)) {
      event.stopPropagation();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleCloseTooltip);

    return () => {
      document.removeEventListener("mousedown", handleCloseTooltip);
    };
  });

  const arrowClass = useMemo(() => {
    switch (placement) {
      case "top":
        return "border-t-neutral-N700 -bottom-2 border-x-8 border-t-8 border-x-transparent";
      case "bottom":
        return "border-b-neutral-N700 -top-2 border-x-8 border-b-8 border-x-transparent";
      case "left":
        return "border-l-neutral-N700 -right-2 border-y-8 border-l-8 border-y-transparent";
      case "right":
        return "border-r-neutral-N700 -left-2 border-y-8 border-r-8 border-y-transparent";
      default:
        return;
    }
  }, [placement]);

  return (
    <Popover className={classNames("relative block", className)}>
      {({ open }) => (
        <>
          <Popover.Button
            className={"flex items-center focus:outline-none"}
            data-testid={testid + "__tooltip"}
            onMouseEnter={() => onHover(open, "onMouseEnter")}
            onMouseLeave={() => onHover(open, "onMouseLeave")}
            onClick={() => handleClick(open)}
            ref={buttonRef}
          >
            {children}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter={"transition-opacity duration-100 ease-out"}
            enterFrom={"opacity-0"}
            enterTo={"opacity-100"}
            leave={"transition duration-75 ease-out"}
            leaveFrom={"opacity-100"}
            leaveTo={"opacity-0"}
            show={enableHover ? openState : undefined}
          >
            <Popover.Panel
              className={
                "bg-neutral-N700 z-10 inline-flex max-w-7xl items-center justify-center rounded px-3 py-2"
              }
              data-testid={testid + "__tooltip__message"}
              ref={setPopperContent}
              style={styles["popper"]}
              {...attributes["popper"]}
            >
              {customContent ?? (
                <Typography
                  type={"body-b3"}
                  className={"text-invert min-w-[12rem] text-center text-xs"}
                >
                  {content}
                </Typography>
              )}

              <div
                ref={setPopperArrow}
                className={arrowClass}
                style={styles["arrow"]}
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default Tooltip;
