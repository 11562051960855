"use client";

import { useMemo, useState } from "react";

import { AccessesKeyEnum } from "@saas/account/utils";
import { env } from "@saas/config/shared";
import { useFlagStatus } from "@saas/flags/feature";
import {
  ChatNotificationContainer,
  NotificationContainer,
} from "@saas/notification/feature";
import { MenuIcon } from "@saas/shared/icon";
import {
  Divider,
  Logo,
  NewIndicator,
  ScheduleDemoButton,
} from "@saas/shared/ui";
import { AppNameEnum, classNames } from "@saas/shared/utils";

import { useEventListener } from "usehooks-ts";

export interface TopNavigationBarProps {
  accountMenu: () => JSX.Element;
  handleShowAside?: () => void;
  enabledNotificationR2?: boolean;
  showScheduleDemo?: boolean;
  variantLogo?: AppNameEnum;
  isSubaccount?: boolean;
  accesses?: Array<AccessesKeyEnum>;
  showIndicator?: Record<string, boolean>;
}

export const TopNavigationBar = ({
  accountMenu: AccountMenu,
  handleShowAside,
  enabledNotificationR2,
  showScheduleDemo,
  variantLogo,
  accesses = [],
  isSubaccount = false,
  showIndicator,
}: TopNavigationBarProps) => {
  const isChatNotificationEnabled = useFlagStatus("crm");

  const [sticky, setSticky] = useState(false);

  const isHasNewMenu = useMemo(() => {
    if (!showIndicator) return false;
    return Object.values(showIndicator).some((indicator) => indicator);
  }, [showIndicator]);

  const hasChatAccess = accesses?.includes(AccessesKeyEnum.CHAT_ALL);

  const isSubaccountHasChatConnection = isSubaccount ? hasChatAccess : true;

  const isUserHasChatAccess = isSubaccountHasChatConnection;

  const isChatNotificationVisible =
    isChatNotificationEnabled && isUserHasChatAccess;

  useEventListener("scroll", () => {
    window.scrollY > 0 ? setSticky(true) : setSticky(false);
  });

  return (
    <header
      className={classNames(
        "bg-neutral-N0 z-[5] flex flex-shrink-0 flex-row items-center justify-between gap-4 px-5 py-4 md:px-6 md:py-2",
        sticky && "shadow-elevation-1 sticky top-0"
      )}
    >
      <div className={"mr-auto flex items-center gap-4 md:hidden"}>
        {handleShowAside ? (
          <button
            onClick={handleShowAside}
            data-testid={"home__icon__hamburger-menu"}
            className={"flex"}
          >
            <MenuIcon />
            {isHasNewMenu ? (
              <NewIndicator
                size={"small"}
                className={"absolute top-0 right-2"}
                testid={"journal__icon__indicator-hamburger"}
              />
            ) : null}
          </button>
        ) : null}

        {variantLogo === AppNameEnum.MELAKA_POS ? (
          <Logo variant={"pos"} />
        ) : null}

        {variantLogo === AppNameEnum.MELAKA ? (
          <Logo className={"h-7 w-full"} />
        ) : null}
      </div>

      <div className={"ml-auto flex flex-row items-center justify-end gap-4"}>
        {showScheduleDemo ? (
          <div className={"hidden w-full md:block"}>
            <ScheduleDemoButton
              phoneNumber={env.SUPPORT_PHONE_NO}
              variant={"tertiary"}
            />
          </div>
        ) : null}

        {isChatNotificationVisible ? <ChatNotificationContainer /> : null}
        <NotificationContainer enabledNotificationR2={enabledNotificationR2} />
        <Divider
          className={"min-h-[24px] md:min-h-[42px]"}
          orientation={"vertical"}
        />

        <AccountMenu />
      </div>
    </header>
  );
};

export default TopNavigationBar;
