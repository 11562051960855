"use client";

import { createRef, RefObject, useCallback, useMemo } from "react";

export type UseMultipleDisclosuresProps<Data> = {
  data: ReadonlyArray<Data>;
};

export type UseMultipleDisclosuresReturnType = {
  refs: ReadonlyArray<RefObject<HTMLButtonElement>>;
  toggle: (index?: number) => void;
};

export const useMultipleDisclosures = <Data>({
  data,
}: UseMultipleDisclosuresProps<Data>): UseMultipleDisclosuresReturnType => {
  const refs = useMemo(() => {
    return (
      data.map(() => {
        return createRef<HTMLButtonElement>();
      }) ?? []
    );
  }, [data]);

  const toggle = useCallback(
    (index?: number) => {
      refs.forEach((ref, idx) => {
        const isCurrent = idx === index;

        if (!isCurrent) {
          const isOpen =
            ref.current?.getAttribute("data-headlessui-state") === "open";

          if (isOpen) {
            ref.current?.click();
          }
        }
      });
    },
    [refs]
  );

  return {
    refs,
    toggle,
  };
};

export default useMultipleDisclosures;
