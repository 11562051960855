"use client";

import { queryClient } from "@saas/core";
import { chatQueryKey } from "@saas/crm/data";
import { ChatRoomModel } from "@saas/crm/utils";
import { ChannelEnum } from "@saas/shared/utils";

import { UseGetChatRoomsData } from "../@hook/get-chat-rooms/use-get-chat-rooms";
import { type UseUnreadMessageCountData } from "../@hook/unread-message-count/use-unread-message-count";

export type MarkAsReadHandlerInput = {
  response: {
    requestId: string;
    data: {
      channel: string;
      shopId: string;
      chatRoomId: number;
      unreadChatRoomShop: number;
      unreadChatRoomAll: number;
    };
  };
};

export const markAsReadHandler = ({ response }: MarkAsReadHandlerInput) => {
  const prevData = queryClient.getQueryData<UseGetChatRoomsData>(
    chatQueryKey.allUnreadChatRoomsBychannel([], "")
  );

  // NOTES: Update the existing query if the current data is still > 6
  if (prevData && prevData.data.length > 6) {
    const newData = {
      ...prevData,
      data: prevData.data.filter(
        (item) => item.id !== response.data.chatRoomId
      ),
      metadata: {
        unreadChatRooms: (prevData.metadata.unreadChatRooms ?? []).map((item) =>
          item.shopId === response.data.shopId
            ? {
                ...item,
                count: response.data.unreadChatRoomShop,
              }
            : item
        ),
      },
    };

    queryClient.setQueryData<UseGetChatRoomsData>(
      chatQueryKey.allUnreadChatRoomsBychannel([], ""),
      newData
    );
  } else {
    // NOTES: Invalidate queries and refetch data if the current data <= 6
    queryClient.invalidateQueries(
      chatQueryKey.allUnreadChatRoomsBychannel([], "")
    );
  }

  // NOTES: update channel and marketplace unread count
  queryClient.setQueryData<UseUnreadMessageCountData>(
    chatQueryKey.allUnreadChatRoomsCount(),
    (prevData) => {
      if (!prevData) return {};

      const channelData = prevData[response.data.channel as ChannelEnum];

      return {
        ...prevData,
        [response.data.channel]: {
          totalCount: response.data.unreadChatRoomAll,
          shopList: {
            ...channelData?.shopList,
            [response.data.shopId]: response.data.unreadChatRoomShop,
          },
        },
      };
    }
  );

  // NOTES: update count on chat room list
  queryClient.setQueriesData<UseGetChatRoomsData>(
    {
      queryKey: chatQueryKey
        .allChatRoomsByShop(
          [response.data.channel as ChannelEnum],
          response.data.shopId
        )
        .slice(0, 3),
    },
    (prevData) => {
      if (!prevData) return undefined;

      const newChatRooms = prevData.data.map((room) => {
        return room.id === response.data.chatRoomId
          ? {
              ...room,
              totalUnreadCount: 0,
            }
          : room;
      });

      return {
        ...prevData,
        data: newChatRooms,
      };
    }
  );
};

export const roomMarkAsReadHandler = (data: ChatRoomModel, shopId = "") => {
  queryClient.setQueryData<UseGetChatRoomsData>(
    chatQueryKey.allChatRoomsByShop([data.channel], shopId),
    (prevData) => {
      if (!prevData) return undefined;

      const listChatRooms = prevData?.data ?? [];

      const activeIndex = listChatRooms.findIndex(
        (room) => room.id === data.id
      );

      const newChatRooms = listChatRooms.map((room, index) =>
        index !== activeIndex
          ? room
          : {
              ...room,
              totalUnreadCount: 0,
            }
      );

      return {
        ...prevData,
        data: newChatRooms,
      };
    }
  );
};

export default markAsReadHandler;
