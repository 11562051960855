"use client";

import { queryClient } from "@saas/core";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { activeChannelsQueryKeys } from "@saas/inventory/utils";
import { QueryOptionsInterface } from "@saas/shared/utils";

import {
  getActiveChannels,
  GetActiveChannelsInput,
  GetActiveChannelsOutput,
} from "../../get-active-channels/get-active-channels";

import { useQuery } from "@tanstack/react-query";

export type UseActiveChannelsParams = GetActiveChannelsInput;

export type UseActiveChannelsQueryData = GetActiveChannelsOutput;

export const useActiveChannelsQuery = <
  DerivedQueryData = UseActiveChannelsQueryData
>(
  data?: GetActiveChannelsInput,
  options?: QueryOptionsInterface<UseActiveChannelsQueryData, DerivedQueryData>
) => {
  return useQuery(
    [activeChannelsQueryKeys.all(data)],
    () => {
      return getActiveChannels(data);
    },
    {
      select: options?.select,
      enabled: options?.enabled,
    }
  );
};

export const prefetchActiveChannelsQuery = async (
  query: GetActiveChannelsInput,
  cookie: string
) => {
  await queryClient.prefetchQuery(activeChannelsQueryKeys.all(query), () => {
    return getActiveChannels(query, { headers: { cookie } });
  });
};

export const invalidateActiveChannelsQuery = (
  data?: GetActiveChannelsInput
) => {
  return queryClient.invalidateQueries([activeChannelsQueryKeys.all(data)]);
};

export default useActiveChannelsQuery;
