"use client";

import { queryClient } from "@saas/core";
import { chatQueryKey } from "@saas/crm/data";
import {
  type ChatRoomMetadataInterface,
  type ChatRoomModel,
  type LoadChatRoomsPayloadInterface,
} from "@saas/crm/utils";
import { PaginationInterface } from "@saas/shared/utils";

import { UseGetChatRoomsData } from "../@hook/get-chat-rooms/use-get-chat-rooms";
import { UseUnreadMessageCountData } from "../@hook/unread-message-count/use-unread-message-count";

export type LoadChatRoomsHandlerInput = {
  response: {
    data: Array<ChatRoomModel>;
    metadata: ChatRoomMetadataInterface;
    pagination: PaginationInterface;
    statusCode?: number;
    requestId?: string;
  };
  requestPayload?: Partial<LoadChatRoomsPayloadInterface> & {
    onResolve: (
      data: UseGetChatRoomsData | PromiseLike<UseGetChatRoomsData>
    ) => void;
    onReject: () => void;
  };
};

export type LoadChatRoomsHandlerOutput = void;

export const loadChatRoomsHandler = ({
  response,
  requestPayload,
}: LoadChatRoomsHandlerInput): LoadChatRoomsHandlerOutput => {
  const isRequestSuccess = requestPayload && response.statusCode === 200;

  if (!isRequestSuccess) return;

  requestPayload.onResolve({
    data: response.data,
    metadata: response.metadata,
    pagination: response.pagination,
    statusCode: response.statusCode as number,
  });

  // NOTES: generate unread chat room count
  const unreadMessage = response.metadata.unreadChatRooms?.reduce(
    (acc, item) => {
      const channelData = acc[item.channel];

      if (channelData) {
        acc[item.channel] = {
          shopList: {
            ...channelData.shopList,
            [item.shopId]: item.count,
          },
          totalCount: channelData.totalCount + item.count,
        };
      } else
        acc[item.channel] = {
          shopList: {
            [item.shopId]: item.count,
          },
          totalCount: item.count,
        };

      return acc;
    },
    {} as UseUnreadMessageCountData
  );

  queryClient.setQueryData<UseUnreadMessageCountData>(
    chatQueryKey.allUnreadChatRoomsCount(),
    unreadMessage
  );
};

export default loadChatRoomsHandler;
