"use client";

import Link from "next/link";
import { forwardRef, Fragment, ReactElement, ReactNode } from "react";

import { SVG } from "@saas/shared/icon";
import { classNames } from "@saas/shared/utils";

import { Divider, MenuItem, Tooltip, TooltipProps, Typography } from "..";

import { Menu, Transition } from "@headlessui/react";

interface BaseProps {
  label: string;
  icon?: SVG;
  divider?: boolean;
  testid?: string;
}

interface ButtonProps extends BaseProps {
  as: "button";
  onClick: () => void | Promise<void>;
  disabled?: boolean;
  tooltip?: Omit<TooltipProps, "children" | "className">;
}

// TODO: refactor as MenuItemButton into shared-ui
const DropdownMenuButton = forwardRef<
  HTMLButtonElement,
  { item: ButtonProps; active?: boolean; onClose: () => void }
>(({ item, active, onClose, ...props }, ref) => {
  return (
    <>
      {item.divider ? (
        <Divider orientation={"horizontal"} className={"my-1"} />
      ) : null}

      {item.tooltip && item.disabled ? (
        <Tooltip {...item.tooltip} className={"flex w-full"}>
          <MenuItem
            className={"py-2 px-3"}
            icon={item.icon}
            active={false}
            disabled={true}
          >
            {item.label}
          </MenuItem>
        </Tooltip>
      ) : (
        <button
          {...props}
          type={"button"}
          ref={ref}
          className={"group mx-0 overflow-hidden md:rounded-lg"}
          onClick={() => {
            item.onClick();
            onClose();
          }}
          data-testid={item.testid}
          disabled={item.disabled}
        >
          <MenuItem
            className={"py-2 px-3"}
            icon={item.icon}
            active={!item.disabled && active}
            disabled={item.disabled}
          >
            {item.label}
          </MenuItem>
        </button>
      )}
    </>
  );
});

DropdownMenuButton.displayName = "DropdownMenuButton";

interface LinkProps extends BaseProps {
  as: "a";
  href: string;
}

// TODO: refactor as MenuItemLink into shared-ui
const DropdownMenuLink = forwardRef<
  HTMLAnchorElement,
  { item: LinkProps; active?: boolean }
>(({ item, active, ...props }, ref) => {
  return (
    <>
      {item.divider ? (
        <Divider orientation={"horizontal"} className={"my-1"} />
      ) : null}
      <Link
        href={item.href}
        {...props}
        ref={ref}
        className={"group mx-0 overflow-hidden md:rounded-lg"}
        data-testid={item.testid}
      >
        <MenuItem className={"py-2 px-3"} icon={item.icon} active={active}>
          {item.label}
        </MenuItem>
      </Link>
    </>
  );
});

DropdownMenuLink.displayName = "DropdownMenuLink";

export type DropdownItemProps = ButtonProps | LinkProps;

export interface DropdownMenuProps {
  title?: string;
  items: DropdownItemProps[];
  origin?: "left" | "right";
  children: ReactNode | ((open: boolean) => ReactNode);
  disabled?: boolean;
  testid?: string;
  className?: string;
}

export const DropdownMenu = ({
  title,
  items,
  origin = "left",
  children,
  disabled,
  testid,
  className,
}: DropdownMenuProps) => {
  const originClass = {
    left: "left-0 origin-top-left",
    right: "right-0 origin-top-right",
  };

  return (
    <Menu>
      <span className={"relative"}>
        <Menu.Button data-testid={testid} disabled={disabled}>
          {({ open }) => {
            return (
              children instanceof Function ? children(open) : children
            ) as ReactElement;
          }}
        </Menu.Button>
        <Transition
          as={Fragment}
          enter={"transition ease-out duration-100"}
          enterFrom={"transform opacity-0 scale-95"}
          enterTo={"transform opacity-100 scale-100"}
          leave={"transition ease-in duration-75"}
          leaveFrom={"transform opacity-100 scale-100"}
          leaveTo={"transform opacity-0 scale-95"}
        >
          <Menu.Items
            className={classNames(
              "bg-neutral-N0 shadow-elevation-1 item-start absolute z-10 mt-1 flex w-[222px] flex-col gap-2 rounded-lg p-2",
              originClass[origin],
              className
            )}
          >
            {title ? (
              <Typography
                as={"h3"}
                type={"label-medium"}
                className={"text-le uppercase"}
              >
                {title}
              </Typography>
            ) : null}
            <div className={"flex flex-col"}>
              {items?.map((item) => (
                <Menu.Item key={item.label}>
                  {({ active, close }) => {
                    switch (item.as) {
                      case "button":
                        return (
                          <DropdownMenuButton
                            item={item}
                            active={active}
                            onClose={close}
                          />
                        );
                      case "a":
                        return <DropdownMenuLink item={item} active={active} />;
                    }
                  }}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </span>
    </Menu>
  );
};

export default DropdownMenu;
