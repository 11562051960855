"use client";

import NextScript from "next/script";
import { useEffect } from "react";

import { useIsClient } from "usehooks-ts";

type UsetifulUserRole = "NON_SDET" | "SDET";

type UsetifulTags = {
  userId: string;
  role: UsetifulUserRole;
  marketplaceStatus?: string;
  verifiedStatus?: string;
};

type UsetifulTagsWindow = Window &
  typeof globalThis & {
    usetifulTags: UsetifulTags;
  };

export interface UsetifulScriptProps {
  userId: string;
  userRole: UsetifulUserRole;
  marketplaceStatus?: string;
  verifiedStatus?: string;
  token: string;
}

export const UsetifulScript = ({
  userId,
  userRole,
  marketplaceStatus,
  verifiedStatus,
  token,
}: UsetifulScriptProps) => {
  const isClient = useIsClient();

  useEffect(() => {
    if (isClient) {
      (window as UsetifulTagsWindow).usetifulTags = {
        userId,
        role: userRole,
        marketplaceStatus: marketplaceStatus,
        verifiedStatus: verifiedStatus,
      };
    }
  }, [isClient, userId, userRole, verifiedStatus, marketplaceStatus]);

  if (!token) return null;

  return (
    <NextScript
      id={"usetifulScript"}
      src={"https://www.usetiful.com/dist/usetiful.js"}
      data-token={token}
      strategy={"afterInteractive"}
    />
  );
};

export default UsetifulScript;
