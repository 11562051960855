"use client";

import { setUseSyncChatInfoQuery } from "@saas/crm/data";
import { ChannelEnum } from "@saas/shared/utils";

interface OpenNotificationParams {
  message: string;
  type: "negative" | "positive";
}

export type SyncChatInfoHandlerInput = {
  response: {
    requestId: string;
    data: {
      errorSyncedChannels: ReadonlyArray<{
        channel: ChannelEnum;
        shopIds: ReadonlyArray<string>;
      }>;
      syncStatus: string;
      syncedChannels: ReadonlyArray<{
        channel: ChannelEnum;
        shopIds: ReadonlyArray<string>;
      }>;
    };
  };
  notificationProvider: {
    open: (params: OpenNotificationParams) => void;
  };
  handleChatRoomMessagesAfterManualSync: () => void;
};

export const syncChatInfoHandler = ({
  notificationProvider,
  response,
  handleChatRoomMessagesAfterManualSync,
}: SyncChatInfoHandlerInput) => {
  if (response.data.syncStatus !== "in progress") {
    const errorSyncedChannels = response.data.errorSyncedChannels.map(
      (channel) => channel.channel
    );
    const syncedChannels = response.data.syncedChannels.map(
      (channel) => channel.channel
    );

    const channels =
      syncedChannels.length > 0 ? syncedChannels : errorSyncedChannels;

    setUseSyncChatInfoQuery(
      { channels },
      {
        errorSynchedChannels: errorSyncedChannels,
        syncing: false,
        synchedChannels: syncedChannels,
      }
    );

    if (errorSyncedChannels.length > 0) {
      notificationProvider.open({
        message: `Sinkronisasi ${errorSyncedChannels.length} dari ${
          errorSyncedChannels.length + response.data.syncedChannels.length
        } chat akun gagal, silakan coba lagi!`,
        type: "negative",
      });
    } else {
      notificationProvider.open({
        message: `Sinkronisasi chat berhasil!`,
        type: "positive",
      });
    }

    handleChatRoomMessagesAfterManualSync();
  }
};

export default syncChatInfoHandler;
