"use client";

import { useState } from "react";

import { getCookie, setCookie } from "cookies-next";

export type DictionaryListIndicator = Record<string, boolean>;

export type UseNewIndicatorReturnType = {
  showIndicator: DictionaryListIndicator;
  onHideIndicator: (route: string) => void;
};

export const useNewIndicator = (
  newRoutes: ReadonlyArray<string> = []
): UseNewIndicatorReturnType => {
  const [showIndicator, setShowIndicator] = useState<DictionaryListIndicator>(
    newRoutes.reduce((acc, route) => {
      return {
        ...acc,
        [route]: getCookie(route) === undefined,
      };
    }, {})
  );

  const handleHideIndicator = (route: string) => {
    setCookie(route, true, { maxAge: 60 * 60 * 24 * 365 });
    setShowIndicator((prevIndicator) => {
      return {
        ...prevIndicator,
        [route]: false,
      };
    });
  };

  return {
    showIndicator,
    onHideIndicator: handleHideIndicator,
  };
};

export default useNewIndicator;
