"use client";

import { QueryKey } from "@saas/account/config";
import { FetchError, queryClient } from "@saas/core";
import { QueryOptionsInterface } from "@saas/shared/utils";

import { Updater, useQuery } from "@tanstack/react-query";

export type UseSessionCookieValueQueryData = string | null;

export const useSessionCookieValueQuery = <
  DerivedQueryData = UseSessionCookieValueQueryData
>(
  options?: QueryOptionsInterface<
    UseSessionCookieValueQueryData,
    DerivedQueryData
  >
) => {
  return useQuery<UseSessionCookieValueQueryData, FetchError, DerivedQueryData>(
    [QueryKey.SESSION_COOKIE_VALUE],
    async () => {
      return null;
    },
    {
      ...options,
      select: options?.select,
      enabled: options?.enabled,
      retry: false,
      retryOnMount: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      refetchInterval: false,
    }
  );
};

export const setUseSessionCookieValueQuery = (
  updater: Updater<
    UseSessionCookieValueQueryData | undefined,
    UseSessionCookieValueQueryData
  >
): void => {
  queryClient.setQueryData<UseSessionCookieValueQueryData>(
    [QueryKey.SESSION_COOKIE_VALUE],
    updater
  );
};

export const getUseSessionCookieValueQuery = ():
  | UseSessionCookieValueQueryData
  | undefined => {
  return queryClient.getQueryData<UseSessionCookieValueQueryData>([
    QueryKey.SESSION_COOKIE_VALUE,
  ]);
};

export const prefetchUseSessionCookieValueQuery = async (
  cookie: string
): Promise<UseSessionCookieValueQueryData | null> => {
  const data: UseSessionCookieValueQueryData | null = cookie ?? null;

  await queryClient.prefetchQuery([QueryKey.SESSION_COOKIE_VALUE], () => data);

  return data;
};

export const invalidateUseSessionCookieValueQuery = () => {
  return queryClient.invalidateQueries([QueryKey.SESSION_COOKIE_VALUE]);
};

export default useSessionCookieValueQuery;
