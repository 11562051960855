"use client";

import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";

import {
  GetActiveChannelsInput,
  useActiveChannelsQuery,
} from "@saas/inventory/data-access";
import { NotificationBar } from "@saas/shared/ui";
import { ChannelEnum } from "@saas/shared/utils";

export const UnavailableStockMappingNotification = () => {
  const router = useRouter();
  const [isShown, setIsShown] = useState(false);

  const shouldShown = useMemo(() => {
    const path = router.pathname.split("/").pop();
    return path === "stock-mapping";
  }, [router.pathname]);

  const { data: channelsList } = useActiveChannelsQuery(
    "offline,online" as GetActiveChannelsInput,
    {
      enabled: shouldShown,
    }
  );

  useEffect(() => {
    if (shouldShown && channelsList) {
      const hasUnavailableChannel = channelsList.data.some(
        (channel) => channel.channelName === ChannelEnum.TIKTOK
      );
      hasUnavailableChannel && setIsShown(true);
    }
  }, [channelsList, shouldShown]);

  return shouldShown && isShown ? (
    <NotificationBar
      title={"Pemetaan stok belum tersedia untuk TikTok."}
      onClose={() => setIsShown(false)}
      message={null}
      variant={"warning"}
      testid={"inventory-stock-mapping-unavailable-channel__notification-bar"}
    />
  ) : null;
};

export default UnavailableStockMappingNotification;
