"use client";

import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment, useCallback, useMemo } from "react";

import { AccessesKeyEnum } from "@saas/account/utils";
import { env } from "@saas/config/shared";
import { mediaQuery } from "@saas/core";
import { NavigationInterface } from "@saas/layout/utils";
import { ChevronDownIcon } from "@saas/shared/icon";
import {
  CounterBubble,
  Divider,
  Logo,
  MenuItem,
  NewIndicator,
  ScheduleDemoButton,
} from "@saas/shared/ui";
import {
  classNames,
  NewMenuEnum,
  useMultipleDisclosures,
} from "@saas/shared/utils";

import { Disclosure } from "@headlessui/react";
import { useWindowSize } from "usehooks-ts";

export interface AsideNavigationProps {
  navigation: ReadonlyArray<NavigationInterface>;
  showScheduleDemo?: boolean;
  totalUnreadChatRoom?: number;
  accesses?: AccessesKeyEnum[];
  showIndicator?: Record<string, boolean>;
  onNavigate?: (route?: string) => void;
}

export const AsideNavigation = ({
  navigation,
  totalUnreadChatRoom,
  showScheduleDemo,
  accesses,
  showIndicator,
  onNavigate: handleNavigate,
}: AsideNavigationProps) => {
  const { asPath } = useRouter();

  const isCurrentChild = useCallback(
    (child?: NavigationInterface) => asPath.includes(child?.href || ""),
    [asPath]
  );

  const { refs, toggle } = useMultipleDisclosures({ data: navigation });

  const { width } = useWindowSize();
  const isDesktop = width >= mediaQuery.md;

  const withActiveNavigation = useMemo(() => {
    return navigation.map((item) => {
      const isExternal = item.href.startsWith("http");
      const isParentActive = isExternal
        ? false
        : item.href === "/"
        ? asPath === "/"
        : asPath.startsWith(item.href);
      let isChildActive = false;

      if (item.children?.length) {
        item.children = item.children.map((child) => {
          if (isCurrentChild(child)) {
            isChildActive = true;
          }

          // check if access from navigation is match with accesses props
          if (accesses?.length && accesses && child.accesses) {
            const isAccessMatch = accesses.some((access) =>
              child.accesses?.includes(access)
            );

            if (!isAccessMatch) {
              return {
                ...child,
                enabled: false,
              };
            }
          }

          return {
            ...child,
            isActive: isCurrentChild(child),
            isNew: showIndicator ? showIndicator[child.href] : false,
          };
        });
      }

      // check if access from navigation is match with accesses props
      if (accesses?.length && accesses && item.accesses) {
        const isAccessMatch = accesses.some((access) =>
          item.accesses?.includes(access)
        );

        if (!isAccessMatch) {
          return {
            ...item,
            enabled: false,
          };
        }
      }

      return {
        ...item,
        isActive: isParentActive || isChildActive,
        isNew: showIndicator ? showIndicator[item.href] : false,
      };
    });
  }, [navigation, asPath, accesses, showIndicator, isCurrentChild]);

  const NewBadge = () => (
    <span>
      {isDesktop ? (
        <NewIndicator
          size={"big"}
          showLabel={true}
          testid={"journal__icon__indicator-menu"}
        />
      ) : (
        <span
          data-testid={"journal__icon__indicator-menu"}
          className={
            "bg-orange-O600 label-medium relative flex rounded-3xl py-[2px] px-[7px] text-white"
          }
        >
          Baru
        </span>
      )}
    </span>
  );

  return (
    <aside
      className={
        "bg-neutral-N0 border-neutral-N200 z-10 min-h-screen border-r md:fixed md:inset-y-0 md:flex md:w-[248px] md:flex-col"
      }
    >
      <div className={"flex flex-grow flex-col overflow-y-auto"}>
        <div className={"m-6 flex flex-shrink-0 items-center"}>
          <Logo />
        </div>
        <nav className={"mr-2 flex flex-1 flex-col gap-1"}>
          {showScheduleDemo ? (
            <div className={"grid w-full pb-3 pl-6 md:hidden"}>
              <ScheduleDemoButton
                phoneNumber={env.SUPPORT_PHONE_NO}
                variant={"tertiary"}
              />
            </div>
          ) : null}

          {withActiveNavigation.map((item, index) => {
            const isDefaultOpen = item.isActive;
            const hasSubmenu =
              item.children?.length &&
              item.children?.some((child) => {
                return child?.enabled;
              });

            return item.enabled ? (
              hasSubmenu ? (
                <Disclosure key={item.label} defaultOpen={isDefaultOpen}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className={"overflow-hidden rounded-r-xl text-left"}
                        ref={refs[index]}
                        data-id={item.testid}
                        data-open={open}
                        onClick={() => toggle(index)}
                        data-testid={"aside-navigation__button__menu"}
                      >
                        <MenuItem
                          className={
                            "block w-full gap-[10px] py-2 pr-2 pl-6 md:flex"
                          }
                          icon={item.icon}
                          active={open}
                          hasSubmenu={!!item.children?.length}
                        >
                          <span
                            className={
                              "flex w-full items-center justify-between gap-2"
                            }
                            data-testid={item.testid}
                          >
                            {item.label}

                            <ChevronDownIcon
                              className={classNames(
                                "text-le transition",
                                open ? "rotate-180" : "rotate-0"
                              )}
                            />
                          </span>
                        </MenuItem>
                      </Disclosure.Button>
                      <Disclosure.Panel className={"flex flex-col gap-1"}>
                        {item.children?.map((child) => {
                          return child.enabled ? (
                            <Link
                              key={child.label}
                              href={child.href}
                              className={
                                "group block w-full cursor-pointer overflow-hidden rounded-r-xl"
                              }
                              target={child.target}
                              data-testid={child.testid}
                              data-id={child.testid}
                              onClick={() => {
                                handleNavigate?.(
                                  child.isNew ? child.href : undefined
                                );
                              }}
                            >
                              <span
                                className={classNames(
                                  "flex items-center gap-2 rounded py-3 pr-2 pl-[56px]",
                                  isCurrentChild(child)
                                    ? "bg-neutral-N100"
                                    : "group-hover:bg-neutral-N100 group-focus:bg-neutral-N100 bg-white"
                                )}
                              >
                                <span
                                  className={classNames(
                                    "text-left",
                                    isCurrentChild(child)
                                      ? "text-button body-b2 semibold"
                                      : "text-he body-b2"
                                  )}
                                >
                                  {child.label}
                                </span>

                                {child.isNew ? <NewBadge /> : null}
                              </span>
                            </Link>
                          ) : null;
                        })}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ) : (
                <Fragment key={item.label}>
                  {item.gap ? (
                    <div className={"px-6"}>
                      <Divider orientation={"horizontal"} />
                    </div>
                  ) : null}

                  <Link
                    href={item.href}
                    className={classNames(
                      "group block w-full cursor-pointer overflow-hidden rounded-r-xl",
                      item.sticky && "absolute bottom-0 z-10",
                      item.className
                    )}
                    target={item.target}
                    data-testid={item.testid}
                    data-id={item.testid}
                    onClick={() => {
                      handleNavigate?.(item.isNew ? item.href : undefined);
                      toggle();
                    }}
                  >
                    <MenuItem
                      className={"gap-[10px] py-2 pr-2 pl-6"}
                      icon={item.icon}
                      active={item.isActive}
                      hasSubmenu={!!hasSubmenu}
                    >
                      <span className={"inline-flex w-full items-center gap-2"}>
                        {item.label}
                        {item.isNew ? <NewBadge /> : null}

                        {item.keyIndicator === NewMenuEnum.CRM &&
                        totalUnreadChatRoom ? (
                          <CounterBubble
                            counter={totalUnreadChatRoom}
                            className={"ml-auto"}
                            testid={`${item.testid}__icon__unread-chat-room`}
                          />
                        ) : null}
                      </span>
                    </MenuItem>
                  </Link>
                </Fragment>
              )
            ) : null;
          })}
        </nav>
      </div>
    </aside>
  );
};

export default AsideNavigation;
